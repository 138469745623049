import React from 'react';
import { graphql } from 'gatsby';
import logger from 'utils/logger';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import {
  CoverContact,
} from 'components/Sections';
import { SectionLabel } from 'components/Text';

const SectionHello = styled.section`
  ${tw`
    pt-4
    lg:pt-8
    pb-2
  `};
`;
const SectionLocations = styled.section`
  ${tw`
    py-2
    lg:py-6
    bg-gray-200
  `};
`;
const ColHelloLeft = styled.div`
  ${tw`
    md:w-1/3
    xl:w-1/3
    pr-2
  `};
`;
const ColHelloRight = styled.div`
  ${tw`
    md:w-2/3
    xl:w-1/2
    pr-2
  `};
`;
const ColContactIntro = styled.h1`
  ${tw`
    w-full
    font-light
    tracking-tight
    lg:text-hero
    xl:text-hero
    mb-5
  `};
`;
const ColContact = styled.div`
  ${tw`
    w-full
    md:w-1/2
    pr-2
    mb-3
    lg:mb-6
  `};
`;
const ContactTitle = styled.span`
  ${tw`
    block
    font-thin
    font-serif
    tracking-wide
    lg:text-lg
    xl:text-xl
    mb-3
  `};
  &::after {
    ${tw`block absolute bg-gray-200 w-2 mt-1.5`};
    content: '';
    height: 2px;
  }
`;
const Phone = styled.a`
  ${tw`
    font-thin
    font-serif
    lg:text-xl
    xl:text-2xl
    block
    mb-1
  `};
`;
const Email = styled.a`
  ${tw`
    font-thin
    font-serif
    lg:text-xl
    xl:text-2xl
    block
    mb-1
  `};
`;
const ColLocation = styled.div`
  ${tw`
    w-full
    lg:w-1/3
    pr-1px
    mb-1px
  `};
`;
const LocationCard = styled.div`
  ${tw`
    bg-white
    py-3
    lg:py-8
    px-1/12
  `};
`;
const Country = styled.div`
  ${tw`
    text-lg
    font-light
    text-gray-400
    lg:text-xl
    xl:text-2xl
    tracking-tight
    mb-1.5
    md:mb-3
  `};
`;
const City = styled.div`
  ${tw`
    text-2xl
    font-semibold
    lg:text-3xl
    xl:text-4xl
    tracking-tighter
    mb-0.5
  `};
`;
const Greeting = styled.div`
  ${tw`
    font-semibold
    lg:text-xl
    xl:text-2xl
    block
    mb-1
  `};
`;
const LocationPhone = styled.a`
  ${tw`
    font-thin
    font-serif
    lg:text-xl
    xl:text-lg
    block
    mb-0.5
  `};
`;
const LocationEmail = styled.a`
  ${tw`
    font-thin
    font-serif
    lg:text-xl
    xl:text-lg
    block
    mb-0.5
  `};
`;

class Page extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      greeting: 'Hello',
      currentCount: 0
    }
  }

  componentDidMount () {
    this.intervalId = setInterval(this.timer.bind(this), 1200);
  }

  componentWillUnmount () {
    clearInterval(this.intervalId);
  }

  timer () {
    const { currentCount } = this.state;
    let greeting = 'Hello'
    if (currentCount === 2) {
      greeting = 'Hola';
    }
    if (currentCount === 3) {
      greeting = 'Hi';
    }
    if (currentCount === 4) {
      greeting = 'Buenas';
    }
    if (currentCount === 5) {
      greeting = '你好嗎';
    }
    if (currentCount === 6) {
      greeting = 'Hallo';
    }
    if (currentCount === 7) {
      greeting = 'こんにちは';
    }
    const count = currentCount < 7 ? currentCount + 1 : 1;

    this.setState({
      currentCount: count,
      greeting
    })
  }

  render () {
    logger.template(__filename, '>', 'render()');
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    const siteTitle = data.site.siteMetadata.title;
    const {
      title,
      featuredImage
    } = data.contentfulPage;

    const contacts = [
      {
        id: `1`,
        title: `Business Inquires.`,
        phone: `+1 (213) 996-0862`,
        email: `biz@thinklo.com`,
      },
      {
        id: `2`,
        title: `Media Inquiries.`,
        phone: `+1 (424) 319-0893`,
        email: `pr@thinklo.com`,
      },
      {
        id: `3`,
        title: `Join us.`,
        phone: `+1 (213) 996-0861`,
        email: `jobs@thinklo.com`,
      },
      {
        id: `4`,
        title: `Everything Else.`,
        phone: `+1 (213) 996-0861`,
        email: `hello@thinklo.com`,
      }
    ];
    const locations = [
      {
        id: `loc-1`,
        country: `USA`,
        city: `Los Angeles`,
        phone: `+1 (213) 996-0861`,
        email: `la@thinklo.com`,
      },
      {
        id: `loc-2`,
        country: `Colombia`,
        city: `Bogotá`,
        phone: `+57 (1) 253-1501`,
        email: `bog@thinklo.com`,
      },
      {
        id: `loc-3`,
        country: `Hong Kong`,
        city: `Mong Kok`,
        phone: `+1 (424) 319-0893`, //+852 30086626  +852 30018896
        email: `hk@thinklo.com`,
      }
    ];

    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Contact" />
        <Menu colorScheme={`dark`} />
        <Fade>
          <CoverContact
            bgColor={`gray-800`}
            title={`${this.state.greeting}`}
            background={{
              image: featuredImage && featuredImage.cover.fixed.src,
              video: featuredImage && (featuredImage.videoLoop && featuredImage.videoLoop.file.url),
            }}
            content={`No matter where you are, we'd love to meet you!`}
          />
          <article>
            <SectionHello>
              <Container>
                <Row>
                  <ColHelloLeft>
                    <SectionLabel label={`Contact`} />
                  </ColHelloLeft>
                  <ColHelloRight>
                    <Row>
                      {/*
                      <ColContactIntro>
                        No matter where you are, we'd love to meet you!
                      </ColContactIntro>
                      */}
                      {contacts && contacts.map((contact) => {
                        return (
                          <ColContact key={contact.id}>
                            <ContactTitle>{contact.title}</ContactTitle>
                            <Phone
                              href={`tel:${contact.phone.replace(/[^\d\+]/g,"")}`}
                              target="_blank"
                            >
                              {contact.phone}
                            </Phone>
                            <Email
                              href={`mailto:${contact.email}`}
                              target="_blank"
                            >
                              {contact.email}
                            </Email>
                          </ColContact>
                        )
                      })}
                    </Row>
                  </ColHelloRight>
                </Row>
              </Container>
            </SectionHello>
            <SectionLocations>
              <Container>
                <Row>
                  {locations && locations.map((contact) => {
                    return (
                      <ColLocation>
                        <LocationCard>
                          <City>
                            {contact.city}
                          </City>
                          <Country>
                            {contact.country}
                          </Country>
                          {/*
                          <Greeting>
                            {contact.phone}
                          </Greeting>
                          */}
                          <LocationPhone
                            href={`tel:${contact.phone.replace(/[^\d\+]/g,"")}`}
                            target="_blank"
                          >
                            {contact.phone}
                          </LocationPhone>
                          <LocationEmail
                            href={`mailto:${contact.email}`}
                            target="_blank"
                          >
                            {contact.email}
                          </LocationEmail>
                        </LocationCard>
                      </ColLocation>
                    )
                  })}
                </Row>
              </Container>
            </SectionLocations>
          </article>
        </Fade>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      ...Page
    }
  }
`;
